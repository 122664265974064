import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import(/* webpackChunkName: "manager" */'@/views/Login.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Accueil',
      },
    },
    {
      path: '/cgu',
      name: 'termsAndConditions',
      component: () => import(/* webpackChunkName: "manager" */'@/views/TermsAndConditions.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Conditions Générales d\'Utilisation',
      },
    },
    {
      path: '/pre-enregistrement',
      name: 'preRegistration',
      component: () => import(/* webpackChunkName: "manager" */'@/views/PreRegistration.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Pré-enregistrement',
      },
    },
    {
      path: '/convention/:hash',
      name: 'convention',
      component: () => import(/* webpackChunkName: "manager" */'@/views/Convention.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Signature Convention',
      },
    },
    // {
    //   path: '/error-404',
    //   name: 'error-404',
    //   component: () => import(/* webpackChunkName: "404" */'@/views/error/Error404.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '*',
    //   redirect: 'error-404',
    // },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  if (!['login', 'convention'].includes(to.name) && !isLoggedIn) return next({ name: 'login' })

  return next()
})

export default router